
import { Component, Vue } from 'vue-property-decorator';
import { convertISOtoLocalTime } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    Multiselect,
  },
})
export default class EditShift extends Vue {
  selectedColumnType = 'DETAILS';

  userSelectionVal = '';

  yourValue = '';

  refreshResponseDataKey = 0;

  editShiftChecklistPopup = false;

  editShiftIsLoading = false;

  allDay = false;

  editShiftGroupSelected = '';

  templateOptions: any[] = [];

  shiftTemplates: any;

  selectedTemplate = -1

  shiftEndTimeOptions: any[] = ['00:00'];

  timeOptions: any[] = [];

  shiftDate = '';

  dataLoading = false;

  userOptions: any[] = [];

  assetOptions: any[] = [];

  assetType = '';

  errorMessages = { assetType: '', assetName: '', username: '' }

  dataErrorMessage = '';

  userName = '';

  assetName = '';

  allAssetTypes: any;

  shiftStartTime='';

  shiftEndTime='';

  shiftColor = '';

  disableShiftStartTime = false;

  disableShiftEndTime = false;

  get activeComponent() {
    return scheduleModule.activeComponent;
  }

  get activeColumns() {
    return scheduleModule.activeComponentResponseColumns;
  }

  get scheduledRowComponents(): any[] {
      return scheduleModule.scheduledRowComponents;
  }

  closeEditShiftPopup(componentId, start_date = 0, end_date = 0) {
    this.$emit('close-edit-shift-popup', componentId, start_date, end_date);
  }

  deleteShiftPopup() {
    this.activeComponent.component_id = this.activeComponent.ID;
    this.$emit('confirm-delete-shift', this.activeComponent);
  }

  timeToMilitaryTimeString(time) {
    function pad(n, width, z) {
        const znew = z || '0';
        const nnew = `${n}`;
        return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

    const hour = time.split(':')[0];
    let minutes = time.split(':')[1];
    minutes = minutes.replace('am', '');
    minutes = minutes.replace('pm', '');
    const meridian = time.includes('am') ? 'AM' : 'PM';
    if (meridian === 'AM' && hour === 12) {
      return `${pad(0, 2, '0')}:${pad(minutes, 2, '0')}`;
    } if (meridian === 'AM') {
      return `${pad(hour, 2, '0')}:${pad(minutes, 2, '0')}`;
    } if (meridian === 'PM' && hour === 12) {
      return `${pad(12, 2, '0')}:${pad(minutes, 2, '0')}`;
    }
      return `${pad(parseInt(hour) + 12, 2, '0')}:${pad(minutes, 2, '0')}`;
  }

  async editShiftSave() {
    // @ts-ignore
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }

    let offset;
    let errorFlag = false;
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const k in this.errorMessages) {
      this.errorMessages[k] = '';
    }

    if (this.assetType === '') {
      errorFlag = true;
      this.errorMessages.assetType = 'Please select asset type';
    }

    if (this.assetName === '') {
      errorFlag = true;
      this.errorMessages.assetName = 'Please select asset name';
    }

    if (this.userName === '') {
      errorFlag = true;
      this.errorMessages.username = 'Please select a user';
    }

    if (errorFlag) return;

    let startTime; let endTime; let
shiftTemplateID;
    if (this.selectedTemplate >= 0) {
      startTime = this.shiftTemplates[this.selectedTemplate].StartTime;
      endTime = this.shiftTemplates[this.selectedTemplate].EndTime;
      scheduleModule.addPersonnelScheduleShiftTemplates({
        id: this.shiftTemplates[this.selectedTemplate].ID,
        name: this.shiftTemplates[this.selectedTemplate].TemplateName,
        color: this.shiftTemplates[this.selectedTemplate].Color,
      });
    shiftTemplateID = this.shiftTemplates[this.selectedTemplate].ID;
    } else if (this.allDay) {
        offset = (new Date().getTimezoneOffset()) / 60;

        startTime = '00:00';
        endTime = '23:59';
      } else {
        startTime = this.timeToMilitaryTimeString(this.shiftStartTime);
        endTime = this.timeToMilitaryTimeString(this.shiftEndTime);
      }

  offset = (new Date().getTimezoneOffset()) / 60;
  const originalStartTimeStr = startTime;
  const originalEndTimeStr = endTime;

  startTime = new Date(`${this.shiftDate.split('T')[0]}T${startTime}:00`);
  endTime = new Date(`${this.shiftDate.split('T')[0]}T${endTime}:00`);
  startTime.setTime(startTime.getTime() + (offset * 60 * 60 * 1000));
  endTime.setTime(endTime.getTime() + (offset * 60 * 60 * 1000));
  if (parseFloat(originalEndTimeStr.replace(':', '.')) <= parseFloat(originalStartTimeStr.replace(':', '.'))) {
    endTime.setDate(endTime.getDate() + 1);
  }
  startTime.setDate(startTime.getDate() - 1);
  endTime.setDate(endTime.getDate() - 1);

  const startDatetimeString = `${startTime.getFullYear()}-${pad(startTime.getMonth() + 1, 2, '0')}-${
    pad(startTime.getDate(), 2, '0')}T${pad(startTime.getHours(), 2, '0')}:${
      pad(startTime.getMinutes(), 2, '0')}:${pad(startTime.getSeconds(), 2, '0')}`;
  const endDatetimeString = `${endTime.getFullYear()}-${pad(endTime.getMonth() + 1, 2, '0')}-${
    pad(endTime.getDate(), 2, '0')}T${pad(endTime.getHours(), 2, '0')}:${
      pad(endTime.getMinutes(), 2, '0')}:${pad(endTime.getSeconds(), 2, '0')}`;

  for (let i = 0; i < scheduleModule.totalComponents.length; i++) {
    if (this.activeComponent.ID === scheduleModule.totalComponents[i].ID) {
      scheduleModule.setFilterScheduledComponentDetails({
        component_index: i,
        start_date: startDatetimeString,
        end_date: endDatetimeString,
        well: this.assetName,
username: this.userName,
        asset_type: this.assetType,
        shift_template_id: shiftTemplateID,
row_id: this.assetName,
      });
      break;
    }
  }

    // @ts-ignore
    this.closeEditShiftPopup(this.activeComponent.ID, startTime, endTime);
  }

  changeAllDay() {
    this.allDay = !(this.allDay);
    if (this.allDay === false) {
      this.disableShiftStartTime = false;
      this.disableShiftEndTime = false;
    } else {
      this.disableShiftStartTime = true;
      this.disableShiftEndTime = true;
    }
  }

  async setUserOptions() {
    this.userOptions.push(scheduleModule.accountDetails.map((item) => ({ value: item.email, text: item.name })));
  }

  async getAllAssetOptions() {
    let detailedComponent; let
currentComponent;
    this.allAssetTypes = {
      Area: [],
      Pad: [],
      Well: [],
      Route: [],
    };
    for (let x = 0; x < this.scheduledRowComponents.length; x++) {
      detailedComponent = this.getFullComponentDetailsByID(this.scheduledRowComponents[x].title_row.selection_id);
      if (detailedComponent != null) {
        this.allAssetTypes[detailedComponent.AssetType].push(detailedComponent.Asset);
        if (this.activeComponent.RowID === detailedComponent.ID) currentComponent = detailedComponent;
      }
    }
    this.setItemDetails();
    if (currentComponent) this.assetName = currentComponent.Asset;
  }

  getFullComponentDetailsByID(id) {
    if (scheduleModule.activePersonnelSchedule != null) {
      // @ts-ignore
      for (let x = 0; x < scheduleModule.activePersonnelSchedule.Rows.length; x++) {
          // @ts-ignore
        if (id === scheduleModule.activePersonnelSchedule.Rows[x].ID) {
          // @ts-ignore
          return scheduleModule.activePersonnelSchedule.Rows[x];
        }
      }
    }
  }

  async setAssetOptions() {
    if (this.allAssetTypes) {
      this.assetName = '';
      this.assetOptions = this.allAssetTypes[`${this.assetType}`];
    }
  }

  getStartTime() {
    return convertISOtoLocalTime(this.activeComponent.StartTime);
  }

  getEndTime() {
    return convertISOtoLocalTime(this.activeComponent.EndTime);
  }

  setItemDetails() {
    if (this.activeComponent) {
      this.shiftStartTime = this.getStartTime();
      this.shiftEndTime = this.getEndTime();
      this.assetType = this.activeComponent.AssetType;
      this.setAssetOptions();
      this.userName = this.activeComponent.Username;
    }
  }

  getDropdownTimeString(time) {
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }
    if (time.getHours() === 0) {
      return `12:${pad(time.getMinutes(), 2, '0')}am`;
    } if (time.getHours() < 12) {
    return `${time.getHours()}:${pad(time.getMinutes(), 2, '0')}am`;
    } if (time.getHours() === 12) {
    return `12:${pad(time.getMinutes(), 2, '0')}pm`;
    } if (time.getHours() <= 23) {
      return `${time.getHours() - 12}:${pad(time.getMinutes(), 2, '0')}pm`;
    }
    return '';
  }

  async created() {
    this.shiftDate = `${this.activeComponent.StartTime}Z`;
    this.setUserOptions();
    this.getAllAssetOptions();
    this.setTimeOptions();
    this.shiftStartTime = this.getDropdownTimeString(new Date(`${this.activeComponent.StartTime}Z`));
    this.shiftEndTime = this.getDropdownTimeString(new Date(`${this.activeComponent.EndTime}Z`));
    await this.getShiftTemplates();
    for (let i = 0; i < this.shiftTemplates.length; i++) {
      if (this.activeComponent.TemplateID === this.shiftTemplates[i].ID) {
        this.selectedTemplate = i;
        break;
      }
    }
  }

  setTimeOptions() {
    function pad(n, width, z) {
      const znew = z || '0';
      const nnew = `${n}`;
      return nnew.length >= width ? nnew : new Array(width - nnew.length + 1).join(znew) + nnew;
    }
    for (let i = 0; i <= 23; i++) {
      for (let j = 0; j < 60; j += 15) {
      if (i === 0) {
        if (j === 0) {
          this.timeOptions.push('12:00am');
        } else {
          this.timeOptions.push(`12:${pad(j, 2, '0')}am`);
        }
      } else if (i < 12) {
        if (j === 0) {
          this.timeOptions.push(`${i}:00am`);
        } else {
          this.timeOptions.push(`${i}:${pad(j, 2, '0')}am`);
        }
      } else if (i === 12) {
        if (j === 0) {
          this.timeOptions.push(`${i}:00pm`);
        } else {
          this.timeOptions.push(`${i}:${pad(j, 2, '0')}pm`);
        }
      } else if (j === 0) {
          this.timeOptions.push(`${i - 12}:00pm`);
        } else {
          this.timeOptions.push(`${i - 12}:${pad(j, 2, '0')}pm`);
        }
      }
    }
    this.setShiftEndTimeOptions(this.shiftStartTime);
  }

  setShiftEndTimeOptions(value) {
    if (typeof value === 'string' && value.constructor === String) {
      this.shiftEndTimeOptions = this.timeOptions.slice();
    }
  }

  async getShiftTemplates() {
    this.templateOptions = [{ value: -1, text: 'Custom' }];
    this.shiftTemplates = await scheduleModule.getShiftTemplates();
    if (this.shiftTemplates) {
      for (let i = 0; i < this.shiftTemplates.length; i++) {
        this.templateOptions.push({ value: i, text: this.shiftTemplates[i].TemplateName });
      }
    }
  }
}
